.big-data-description {
  margin: 30px 0;

  @media (min-width: $bp-lg) {
    margin: 50px 0;
    padding: 0 18px;
  }
}

.section-facts__description {
  margin-bottom: 35px;

  @media(min-width: $bp-lg) {
    padding: 0 70px 0 0;
  }
}

.section-facts__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  visibility: hidden;

  @media (min-width: $bp-lg) {
    margin-top: -70px;
  }
}

.section__description {
  @media (min-width: $bp-lg) {
    padding: 0 114px;
  }
}

.content-page-wrapper {
  @media (min-width: $bp-lg) {
    padding-left: 34px;
  }
}
