// Screen visibility
//
// Display an element only on small screens via `.only-sm` class
// Display an element only on large screens via `.only-lg` class
.only-sm {
   @media(min-width: $bp-lg) {
      display: none !important;
   }
}

.only-lg {
   @media(max-width: $bp-lg - 1) {
      display: none !important;
   }
}

// Disable Scroll
//
// To apply on body or html to prevent page scroll when a modal is open
//
// .disable-scroll--ios - specific rules for ios (to apply with browser agent detection)
.disable-scroll {
   overflow: hidden;
}

.disable-scroll--ios {
   position: fixed;
   width: 100%;
}

.image {
  display: block;
  width: 100%;
}

.text-highlight {
  color: $color-brand-secondary;

  &--light {
    color: $color-white;
  }
}

.text-neutral {
  color: $color-neutral;
}

.text-left {
  text-align: left;
}
