/*
 * Common styles for all menus.
 * This class should be used for all menus.
 */
.menu {
}

/*
 * Specific styles for the main menu.
 * This class should be used for the main menu only.
 */
.menu-main {
  @media (min-width: $bp-lg) {
    & :nth-child(3) {
      margin-right: 10.5px;
    }
  }
}

.menu__item {
  display: inline-block;
}

.menu__link {
  margin: 0 10.5px;

  font-family: $font-family-base;
  line-height: $line-height-menu-link;
  color: $text-color-light;
  text-decoration: none;

  &.active {
    color: $color-highlight;
  }
  &:hover {
    text-decoration: none;
    color: $color-highlight;
  }
}
