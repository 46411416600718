.slideInUp {
  animation-name: slideInUp;
  animation-duration: 1.8s;
  animation-fill-mode: both;
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }
}

.animation-wrapper {
  visibility: hidden;
}
