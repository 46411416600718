.alert {
  display: block;
  margin-top: 5px;
  font-size: 14px;

  &--box {
    position: relative;
    border: 2px solid;
    margin-bottom: 10px;
    padding: 10px;
    -webkit-border-radius: $alert-box-border-radius;
    -moz-border-radius: $alert-box-border-radius;
    border-radius: $alert-box-border-radius;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: currentColor;
      opacity: .2;
      -webkit-border-radius: $alert-box-border-radius;
      -moz-border-radius: $alert-box-border-radius;
      border-radius: $alert-box-border-radius;
    }
  }

  &--error {
    color: $color-error;
  }

  &--success {
    color: $color-success;
  }

  &.hidden {
    display: none;
  }
}
