.link-learn-more {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family-base;
  font-size: $link-learn-more-font-size;
  text-transform: uppercase;
  color: $color-highlight;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $color-highlight;
    transform: translateX(10px);
  }

  &:before {
    content: '';
    display: block;
    background-color: $color-highlight;
    min-width: $dash-width-link;
    height: $dash-link-height;
    margin-right: $dash-link-margin-right;
  }

  @media (min-width: $bp-lg) {
    font-size: $font-size-base;
  }

  &--left {
    justify-content: flex-start;
  }
}

.item-link {
  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-neutral;

    &:before {
      content: '';
      display: block;
      background-color: $color-neutral;
      min-width: 18px;
      height: $dash-link-height;
      margin-right: $dash-link-margin-right;
    }
  }

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
