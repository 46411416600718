.video-banner {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
