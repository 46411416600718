.section {
  position: relative;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: center;
  padding: 70px 0;

  @media (min-width: $bp-lg) {
    min-height: 100vh;
    padding: 0;
  }
}

.section--hero {
  height: 564px;
  padding: 69px 0 0 0;

  @media (min-width: $bp-lg) {
    height: 100vh;
    padding: 0;
  }
}

.section--page {
  @media (min-width: $bp-lg) {
    min-height: 506px;
  }

  &__content {
    @media (min-width: $bp-lg) {
      min-height: auto;
      padding: 145px 0 160px 0;
    }
  }
}

.section--dark {
  color: $color-brand-primary;
  background-color: $background-color-dark;
}

.section--slider {
  display: flex;
  flex-direction: column;
  color: $color-brand-primary;
  background-color: $color-neutral-dark-light;
  padding-bottom: 40px;

  > .container {
    padding: 0;
  }

  > .columns {
    display: flex;
    flex-direction: column-reverse;
  }

  @media (min-width: $bp-lg) {
    padding: 88px 0 154px 0;

    > .container {
      padding: 0 25px;
    }

    > .columns {
      display: flex;
      flex-direction: row;
      padding-top: 70px;
    }
  }
}

.section--light {
  color: $color-neutral-dark;
  background-color: $background-color-light;
}

.section--background {
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: $bp-lg) {
    background-position: center;
  }
}

.section--big-data {
  padding: 132px 0;
  background-image: url("../images/bg/big-data-bullets-mobile.jpg");

  @media (min-width: $bp-lg) {
    padding: 0;
    background-image: url("../images/bg/big-data-bullets.jpg");
  }
}

.section--snake {
  padding: 47px 0;
  height: 630px;
  background-image: url("../images/bg/snake-mobile.jpg");

  @media (min-width: $bp-lg) {
    padding: 0;
    height: 100vh;
    background-image: url("../images/bg/bg-snake.jpg");
  }

  > .digital-content {
    height: 100%;
    @media (min-width: $bp-lg) {
      padding: 0;
      justify-content: space-between;
      height: 400px;
    }
  }
}

.section--cyber {
  min-height: 1040px;
  padding-top: 55px;

  @media (min-width: $bp-lg) {
    min-height: 1179px;
    padding-top: 0;
  }
}

.section--contact {
  height: 100%;
  padding: 70px 0 60px;

  &__feedback {
    display: flex;
    align-items: center;

    > .section__title {
      line-height: 1.2em;
    }
  }

  @media(min-width: $bp-lg) {
    padding: 140px 0 100px;
  }
}

.section--contact__height {
  margin-top: 54px;
  min-height: 600px;
}

.section--honey {
  padding: 128px 0 108px 0;
  background-image: url("../images/bg/bg-honey-1.png"), url("../images/bg/bg-honey-2-mobile.png");
  background-size: 296px;
  background-position: top -70px left -80px, right -60px bottom -78px;
  background-repeat: no-repeat;
  @media(min-width: $bp-lg) {
    background-image: url("../images/bg/bg-honey-1.png"), url("../images/bg/bg-honey-2.png");
    padding: 0;
    background-size: 590px;
    background-position: top -45px left -150px, right -244px bottom;
  }
}

.section--screenshot {
  padding: 60px 0 70px;
  @media(min-width: $bp-lg) {
    padding: 0;
  }
}

.section--privacy {
  background: transparent;
  height: $height-section-privacy;
  padding: 0;
  z-index: 1;
  @media(min-width: $bp-lg) {
    min-height: 100vh;
    margin-top: 0;
  }
}

.section--contact-home {
  height: $height-section-contact;
  padding: 0;

  > .container {
    z-index: 99;
  }
}

.background-constellation {
  background-image: url("../images/bg/bg-constellation-light.png");
  background-size: 235px;
  background-position: center 39%;
  background-repeat: no-repeat;

  @media (min-width: $bp-lg) {
    background-image: url("../images/bg/bg-constellation-light.png");
    background-repeat: no-repeat;
    background-size: 588px;
    background-position: center center;
  }
}

.section-modules__title {
  font-family: $font-family-highlight;
  font-size: 25px;
  line-height: 1.52em;
  letter-spacing: 16.70px;
  text-transform: uppercase;

  margin: 0;
  padding: 0 25px;
}
