.header-menu {
  @media (max-width: $bp-lg - 1) {
    position: fixed;
    z-index: 0;
    left: 100vw;
    width: 100vw;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    background-color: inherit;
    padding-top: 153px;
    transition: left cubic-bezier(.29, .67, .57, 1.04) 600ms;

    &--open {
      left: 0;
    }

    > .menu__item {
      font-size: 22px;
      line-height: 35px;

      > a:hover {
        text-decoration: none;
      }
    }
  }
}

.header-dropdown {
  @media (max-width: $bp-lg - 1) {
    display: block;
    &.show {
      padding: 0;
      background: none;

      > .dropdown-toggle {
        color: $text-color-light;
      }
    }

    > .dropdown-menu {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      width: 100%;

      font-size: 16px;
      line-height: 26px;
      color: $color-grey;

      > .dropdown-item {
        line-height: inherit;
        margin-bottom: 8px;

        &:hover {
          background: none;
          text-decoration: none;
        }
      }

      &.show {
        background: none;
      }
    }
    > .dropdown-toggle {
      pointer-events: none;
      color: $text-color-light;
    }
  }
}

.btn.header-contact-btn {
  @media (max-width: $bp-lg - 1) {
    margin: 40px 0 0 0;
  }
}
