.burger-menu {
  width: 22px;

  &.cross {
    > .burger-menu__bar:first-child {
      animation-name: anim-burger-top;
    }

    > .burger-menu__bar:nth-child(2) {
      transition: opacity .1s calc(#{$burger-anim-duration} / 2);
      opacity: 0;
    }

    > .burger-menu__bar:last-child {
      animation-name: anim-burger-bottom;
    }
  }

  &.uncross {
    > .burger-menu__bar:first-child {
      animation-name: anim-burger-top-reverse;
    }

    > .burger-menu__bar:nth-child(2) {
      transition: opacity .1s calc(#{$burger-anim-duration} / 3);
      opacity: 1;
    }

    > .burger-menu__bar:last-child {
      animation-name: anim-burger-bottom-reverse;
    }
  }
}

.burger-menu__bar {
  height: $burger-bar-height;
  margin: $burger-bar-spacing 0;
  background: $burger-color;

  animation-duration: $burger-anim-duration;
  animation-fill-mode: forwards;
}
