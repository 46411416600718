header {
  position: relative;
}

.navbar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: $menu-padding-vertical $menu-padding-horizontal;
  z-index: 999;
  box-sizing: border-box;
  background-color: $color-black;

  .btn {
    margin: 0;
  }

  @media (min-width: $bp-lg) {
    position: absolute;
    padding: $menu-padding-vertical-lg $menu-padding-horizontal-lg 0;
    background-color: transparent;
  }
}

.navbar-brand,
.mobile-nav-toggle {
  position: relative;
  z-index: 1;
}

.navbar-brand {
  display: inline-block;
  width: $brand-width;
  box-sizing: border-box;

  @media (min-width: $bp-lg) {
    width: $brand-width-lg;
  }
}

.mobile-nav-toggle {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;

  > .burger-menu {
    margin-left: 9.2%;
  }
}
