.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  z-index: 1;
  width: 258px;
  top: 100%;
  display: none;
  padding: 10px 0;
  color: $color-grey;

  &.show {
    display: block;
    background-color: $color-neutral-dark;
  }
}

.menu__item {
  &.show {
    padding: 5px 0;
    background-color: $color-neutral-dark;

    > .menu__link {
      color: $color-highlight;
    }
  }
}

.dropdown-item {
  display: block;
  padding: 0 14px;
  color: $color-grey;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: $color-highlight;
    text-decoration: none;
    background-color: $color-neutral-dark;
  }

  &:active {
    background-color: darken($color-neutral-dark, 20%);
  }

  &.active {
    color: $color-highlight;
  }
}
