@keyframes anim-burger-top {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-50%);
  }

  50% {
    transform: translateY($burger-bar-translate-top);
  }

  100% {
    transform: translateY($burger-bar-translate-top) rotate(-#{$burger-bar-rotate});
  }
}

@keyframes anim-burger-top-reverse {
  0% {
    transform: translateY($burger-bar-translate-top) rotate(-#{$burger-bar-rotate});
  }

  30% {
    transform: translateY($burger-bar-translate-top);
  }

  50% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes anim-burger-bottom {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(50%);
  }

  50% {
    transform: translateY($burger-bar-translate-bottom);
  }

  100% {
    transform: translateY($burger-bar-translate-bottom) rotate($burger-bar-rotate);
  }
}

@keyframes anim-burger-bottom-reverse {
  0% {
    transform: translateY($burger-bar-translate-bottom) rotate($burger-bar-rotate);
  }

  30% {
    transform: translateY($burger-bar-translate-bottom);
  }

  50% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(0);
  }
}
