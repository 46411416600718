.wrapper-parallax {
  position: relative;
  padding-top: 0;
  @media(min-width: $bp-lg) {
    padding: 0;

  }
}

.container-sticky {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  height: calc(#{$height-section-m-calcul} + #{$image-sticky-width} + 69px);
  top: 58px;

  @media(min-width: $bp-lg) {
    height: calc(100vh + #{$image-sticky-width-lg});
    top: calc(25% - (#{$image-sticky-width-lg} / 2));
  }
}

.image--constellation-sticky {
  margin: 0 auto;
  width: $image-sticky-width;
  position: sticky;
  z-index: 1;
  top: $image-sticky-height;

  @media (min-width: $bp-lg) {
    width: 588px;
    top: 25%;
  }
}
