.list-indicators {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.list-indicators__items {
  line-height: 23px;
  font-weight: 700;
  color: $color-highlight;

  margin-bottom: 26px;

    &:hover {
    transform: translateX(5px);
  }
}
