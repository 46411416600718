/* 	4. Javascript driven animation
		------------------------------
		Animation driven via javascript class addition
*/

/* 4.1 view switch
	 ---------------
	 perform a switch between two view, making them slide from bottom to top.*/

/* It have to keep the absolute positionned child in his scope, and expand/reduce if needed
   with a small transition on min-height */
.switch-inner {
  position: relative;
  transition: min-height .4s linear;
  min-height: 1px;
  overflow: hidden;
}

/* Class to apply on element to switch */
.switch-view {
  overflow: hidden;
  // padding: 1px;
  transform: translate(0, 0);
}

/* Class added via javascript  */
.switch-enter-prepare,
.switch-leave-prepare {
  position: absolute;
  width: 100%;
  overflow: hidden;

  transition: transform .4s linear;
}

.switch-enter-prepare {
  transform: translate(0, 100%);

}

.switch-enter {
  transform: translate(0, 0);
}

.switch-leave {
  transform: translate(0, -100%);

}

.switch-leave-end {
  display: none;
}
