.headline {
  > .anim-show-first, .anim-show-second {
    position: absolute;
    opacity: 0;
  }
}

.headline {
  > .anim-show-first {
    animation: fadeIn 10s linear  1;
    animation-delay: 3s;
    @media(min-width: $bp-lg) {
      animation-delay: 1s;
    }
  }
}

.anim-show-second {
  animation: fadeInStay 10s forwards;
  animation-delay: 6.5s;
  @media(min-width: $bp-lg) {
    animation-delay: 4.5s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  4% {
    opacity: 1;
  }
  21% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInStay {
  0% {
    opacity: 0;
  }
  4% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
