html, body {
  @include font-smoothing();
  font-size: $font-size-base-mobile;
  font-family: $font-family-base;
  line-height: 1.625em;
  font-weight: 400;
  scroll-behavior: smooth;
 // overflow: auto;

  @media (min-width: $bp-lg) {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}

h1, h2, h3,
.h1, .h2, .h3 {
  display: inline-block;
  font-family: $font-family-highlight;
  line-height: 1.10em;
  letter-spacing: 0.28em;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0;

  @media (min-width: $bp-lg) {
    line-height: 1em;
  }
}

h1, .h1 {
  font-size: $font-size-h1-mobile;

  @media (min-width: $bp-lg) {
    font-size: $font-size-h1;
  }
}

h2, .h2 {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
  letter-spacing: $letter-spacing-h2;
}

.title-big {
  font-size: $font-size-h2;

  @media (min-width: $bp-lg) {
    font-size: 65px;
  }
}

.title-bigger {
  @extend .h1;
  line-height: 1.40em;
  letter-spacing: 0.28em;

  @media (min-width: $bp-lg) {
    font-size: 80px;
    line-height: 0.56em;
  }
}

.big-text {
  @extend .h1;
  text-transform: none;
  line-height: 1.10em;
  letter-spacing: 0;

  @media (min-width: $bp-lg) {
    line-height: 1.33em;
  }
}

.title-small {
  font-family: $font-family-base;
  font-size: 20px;
  line-height: 1.8em;
  letter-spacing: 0.5em;
  font-weight: 500;
}

.title-medium {
  @extend .h2;
  letter-spacing: 1px;
  text-transform: none;
}
