.title-privacy {
  margin: 40px -10px 0;
  @media(min-width: $bp-lg) {
    margin: 40px 0 10px 0;
  }
}

.title-digital-biota-section {
  margin: 51px 0 15px;
  @media(min-width: $bp-lg) {
    padding: 0 20px;
  }
}

.title-centric-data {
  margin: 0 0 30px 0;
}

.subtitle-contact-page {
  margin: 22px 0 0;
}

.title-slider {
  font-size: 25px;
  margin-bottom: 20px;
  @media(min-width: $bp-lg) {
    font-size: 32px;
  }
}
