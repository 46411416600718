.privacy {
  > p {
    margin: 0;

    &:nth-child(2) {
      margin-bottom: 20px;
    }
  }

  > h4 {
    margin-bottom: 0;
  }

  a {
    color: blue;

    &:hover {
      color: inherit;
    }
  }
}

.privacy-list {
  padding: 10px 0;

  &__item {
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: block;
      background-color: $color-neutral-dark;
      min-width: $dash-width-link;
      height: $dash-link-height;
      margin-right: $dash-link-margin-right;
    }
  }
}
