.advantages-list {
  margin-top: 25px;

  &__description {
    display: flex;
    align-items: center;
    margin-bottom: 38px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      display: block;
      background-color: $color-highlight;
      min-width: 30px;
      height: 1px;
      margin-right: 10px;
    }
  }
}
