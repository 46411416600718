.btn {
  @include font-smoothing();
  display: inline-block;
  min-width: $btn-width;
  margin: 20px auto;
  box-sizing: border-box;
  padding: 15px 40px;
  border: 1px solid $color-brand-secondary;
  border-radius: $btn-radius;
  z-index: 5;

  color: $color-brand-primary;
  font-size: 22px;
  text-align: center;
  text-decoration: none;
  line-height: $line-height-button-small;
  cursor: pointer;
  transition: color, border .3s ease-in-out;

  &:focus,
  &:hover {
    color: $color-white;
    border-color: $color-white;
    text-decoration: none;
  }

  @media (min-width: $bp-lg) {
    padding: 10px 25px;
    font-size: 15px;
    min-width: $btn-width-lg;
  }
}

.btn--fluid {
  min-width: auto;
}

.btn-wrapper {
  display: block;
  margin-top: 26px;

  @media (min-width: $bp-lg) {
    display: flex;
    justify-content: center;
    margin-top: 55px;
  }
}

.btn--large {
  min-width: 252px;
  font-size: 16.8px;
  line-height: 25px;
  margin: 9px auto;
  @media (min-width: $bp-lg) {
    padding: 13px;
  }
}

.btn-submit {
  min-width: 300px;
  margin: 0;
  font-weight: 700;
  background-color: $color-highlight;

  &:focus,
  &:hover {
    margin: 0;
    transform: translateY(-5px);
    outline-color: transparent;
  }

  @media (min-width: $bp-lg) {
    min-width: 252px;
  }
}
