.list__item {
  opacity: 0;
  @media (min-width: $bp-lg) {
    opacity: 0.3;
  }
}

.list {
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  padding: 0 20px;

  > .anim-fade-first, .anim-fade-second, .anim-fade-third, .anim-fade-fourth {
    position: absolute;
    top: 32%;
    max-width: 270px;
  }

  > .anim-fade {
    animation: fadeIn 10s linear forwards infinite;
  }

  > .anim-fade-first {
    animation-delay: 0.5s;
  }

  > .anim-fade-second {
    animation-delay: 3s;
  }

  > .anim-fade-third {
    animation-delay: 5.5s;
  }

  > .anim-fade-fourth {
    animation-delay: 8s;
  }

  @media (min-width: $bp-lg) {
    display: block;
    height: auto;
    > .anim-fade-first, .anim-fade-second, .anim-fade-third, .anim-fade-fourth {
      position: static;
      max-width: none;
    }

    > .anim-fade {
      animation: fadeInLoop 8s linear forwards infinite;
    }

    > .anim-fade-first {
      animation-delay: 0.5s;
    }

    > .anim-fade-second {
      animation-delay: 2s;
    }

    > .anim-fade-third {
      animation-delay: 3.5s;
    }

    > .anim-fade-fourth {
      animation-delay: 5s;
    }
  }
}

@keyframes fadeInLoop {
  0% {
    opacity: 0.3;
  }
  20% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.6;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.3;
  }
}
