a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: #FFFFFF;
    text-decoration: underline;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  padding: 20px 0;
  color: #AAAAAA;
  background-color: $color-neutral-dark;

  > * {
    margin: 0 25px 10px;
  }

  @media (min-width: $bp-laptop) {
    display: flex;
    height: 114px;
    padding: 0;
  }
}
