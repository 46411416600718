// Icons
//
//  - function get-icon-image
//    returns the URL-encoded svg code that can be used anywhere in css
//  - mixin icon-image
//    adds a background image with and URL-encoded SVG

// Returns the URL-encoded SVG string with provided fill color
// can be used to put an image into a :before/:after
//
// @param $name {string} - the key name of the icon in the list
// @param $color {color} - hex, text or rgba color value
// @param $icons {mapList} - optional, list of icons
@function get-icon-image($name, $color: "#fff", $icons: $icons){
   @return str-replace(map-get($icons, $name), '{COLOR}', '#{str-replace('#{$color}', '#', '%23')}');
}

// Icon background-image mixin
// Allows to quickly define a --variant of an icon with different color
//
// @param $name {string} - the key name of the icon in the list
// @param $color {color} - hex, text or rgba color value
// @param $icons {mapList} - optional, list of icons
@mixin icon-image($name, $color: #fff, $icons: $icons) {
   background-image: url(get-icon-image($name, $color));
}
