.not-found-digit {
  font-size: 150px;
  color: #998665;

  @media(min-width: $bp-lg) {
    font-size: 200px;
    letter-spacing: 55px;
    line-height: 165px;
    margin: 128px 0 0 50px;
  }
}

.not-found-text {
  font-size: 35px;
  letter-spacing: 9.77px;
  @media(min-width: $bp-lg) {
    margin: 0 80px 80px 80px;
  }
}

.btn--back {
  background-color: transparent;
}

.other-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section--error-page {
  height: 100vh;
}
