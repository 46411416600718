.carousel-indicators__items {
  > .icon {
    display: none;
  }

  > span {
    display: none;
  }


  @media (min-width: $bp-lg) {
    display: flex;
    align-items: center;
    max-width: 315px;
    padding: 24px 54px 24px 26px;

    font-family: $font-family-highlight;
    font-size: 20px;
    line-height: 1.1em;
    text-align: left;
    text-transform: uppercase;

    > .icon {
      display: block;
      margin-right: 18px;
    }

    > span {
      display: block;
    }
  }
}
