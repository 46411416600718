// Running text font
@font-face {
  font-family: 'AvenirNextLTPro';
  font-weight: 400;
  font-style: normal;
  font-display: swap; /* Read next point */
  src: local('Avenir Next LT Pro'), url("../fonts/AvenirNextLTPro-Regular.woff") format('woff');
}

@font-face {
  font-family: 'AvenirNextLTPro';
  font-weight: 700;
  font-style: normal;
  font-display: swap; /* Read next point */
  src: local('Avenir Next LT Pro'), url("../fonts/AvenirNextLTPro-Bold.woff") format('woff');
}
