.hero {
  background-color: $background-color-dark;

  &__content {
    z-index: 1;
    height: 100%;
  }

  &--contact {
    height: 490px;
    background-image: url("../images/bg/bg-constellation-dark.png");
    background-repeat: no-repeat;
    background-size: 117px;
    padding: 0;

    @media (min-width: $bp-lg) {
      min-height: 900px;
      background-image: url("../images/bg/bg-constellation-dark.png");
      background-size: 588px;
      background-position: center 163px;
    }
  }
}

.hero--digital-biota {
  height: 460px;
  background-image: url("../images/bg/bg-hero-digital-biota-m.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: $bp-lg) {
    height: 650px;
    background-image: url("../images/bg/bg-hero-digital-biota.jpg");
    background-position: center;
  }
}

.hero--law {
  background-image: url("../images/bg/bg-hero-law-m.jpg");
  background-repeat: no-repeat;
  background-position: center;
  @media (min-width: $bp-lg) {
    background-image: url("../images/bg/bg-hero-law.jpg");
  }
}

.hero--fraud {
  background-image: url("../images/bg/bg-credit-card-m.jpg");
  background-repeat: no-repeat;
  background-position: center;
  @media (min-width: $bp-lg) {
    background-image: url("../images/bg/bg-credit-card.jpg");
  }
}

.hero--quality {
  background-image: url("../images/bg/bg-eye-m.jpg");
  background-repeat: no-repeat;
  background-position: center;
  @media (min-width: $bp-lg) {
    background-image: url("../images/bg/bg-eye.jpg");
  }
}

.hero--gdpr {
  background-image: url("../images/bg/bg-gdpr-m.jpg");
  background-repeat: no-repeat;
  background-position: center;
  @media (min-width: $bp-lg) {
    background-image: url("../images/bg/bg-gdpr.jpg");
  }
}

.hero--epidemio {
  background-image: url("../images/bg/bg-epidemio-m.jpg");
  background-repeat: no-repeat;
  background-position: center;
  @media (min-width: $bp-lg) {
    background-image: url("../images/bg/bg-epidemio.jpg");
  }
}

.hero--privacy {
  height: 400px;
  background-image: url("../images/bg/bg-constellation-light.png");
  background-size: 311px;
  background-repeat: no-repeat;
  background-position: center;
  @media (min-width: $bp-lg) {
    background-image: url("../images/bg/bg-constellation-light.png");
  }
}

.hero--height {
  height: 400px;
  background-size: cover;
  @media (min-width: $bp-lg) {
    height: 669px;
  }
}
