// Icon
//
// An icon element that can be used in two ways:
// - with an background image
//   - avoids to duplicate the SVG code multiple times in case if the icon is used more than once.
// - with inlined SVG
//   - allows to change the color of the icon for the different states of the container as button.
//
// The size of the icon can be set by setting the `font-size` or `width` and `height`
//
// _Compatible in_:
// __Safari__ _( MacOS 8+, IOS 9+)_,
// __Chrome__ (MacOs 36+, Windows 36+, IOS 58+, Android 58+),
// __FireFox__  (MacOS 48+, Windows 48+, IOS 7+),
// __IE11__,
// __Edge 14+__,
// __Samsung Internet 1.6+__
//
.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;

  // in case if used with bg
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  // in case if used with SVG
  > svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
    pointer-events: none;
  }
}

.icon--large {
  font-size: 57px;
}

// Icon BG
//
// An icon element using the image in background.
//
// Avoids to duplicate the SVG code multiple times in case if the icon is used more than once.
//
// This icon element can't change color.
//
// .icon--hdd
// .icon--pentagone
// .icon--pile
//
// Markup: <span class="icon {{modifier_class}}">
//
.icon--hdd {
  @include icon-image('hdd');
}

.icon--pile {
  @include icon-image('pile');
}

.icon--pentagone {
  @include icon-image('pentagone');
}

.icon--pentagone-bullet {
  @include icon-image('pentagone-bullet');
}

.icon--cube {
  @include icon-image('cube');
}


