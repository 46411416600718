.scroll-spy {
  position: fixed;
  z-index: 10;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);

  &__bullet {
    width: 6px;
    height: 6px;
    margin: 10px auto;
    border-radius: 50%;
    background: $scroll-spy-dark-color;
    opacity: 1;

    &.active {
      width: 10px;
      height: 10px;
      opacity: 1;
      background: $scroll-spy-dark-active-color;
    }
  }

  &.fade {
    opacity: 0;
    transition: opacity .4s linear;
  }

  @media(min-width: $bp-lg) {
    right: 5%;

    &__bullet {
      width: 8px;
      height: 8px;

      &.active {
        width: 12px;
        height: 12px;
      }
    }
  }
}
