.carousel {

  > .col--small {
    padding-top: 80px;
  }

  > .col--large {
    padding-top: 30px;
  }

  @media (min-width: $bp-lg) {
    > .col--small {
      padding-top: 0;
    }
    > .col--large {
      padding-top: 0;
    }
  }
}

.carousel-item {
  min-height: 705px;
  @include transition(opacity $carousel-transition-duration);
}

.carousel-indicators {
  margin: 10px 0;
  position: static;

  > .carousel-indicators__items {
    width: 9px;
    height: 9px;
    margin: auto 7px;

    border-radius: 50%;
    background-color: $carousel-indicator-color;
  }

  .active {
    width: 11px;
    height: 11px;
    border: 1px $carousel-indicator-color solid;
    background: none;
    opacity: 1;
  }

  @media (min-width: $bp-lg) {
    position: static;
    display: block;
    left: 0;
    margin: 0;
    padding: 0;
    transform: translateX(0);

    li {
      text-indent: 0;
    }

    > .carousel-indicators__items {
      width: auto;
      height: auto;
      margin: 0;
      border: none;
      background: none;
    }
    .active {
      background-color: #202225;
      border-radius: 5px;
    }
  }
}

.carousel-item__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 313px;
  text-align: left;
  margin: 0 auto;

  @media (min-width: $bp-lg) {
    max-width: 550px;
  }
}

//
// Fade transitions
//
.carousel-fade {
  .active.carousel-item-left,
  .active.carousel-item-right {
    @include transition(opacity $carousel-transition-duration);
  }
}
