.form-group {
  display: block;
  margin-bottom: 32px;
  font-size: 20px;
  color: $form-color;
  text-align: left;

  transition: all .2s;

  > .alert--error {
    display: none;
  }

  &__control {
    display: block;
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba($form-color, .25);
    font-size: 15px;
    color: $color-black;

    transition: all .2s;

    &:focus {
      outline-color: $color-grey-light;
    }
  }

  &--checkbox {
    display: flex;
    margin: 40px 0;

    > .form-group__control {
      flex: 1 0 auto;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      padding: 0;
      background: $color-highlight;
      border: none;

      &:checked {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: $checkbox-checkmark;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
      }

      &:focus {
        outline-color: $color-white;
      }
    }

    &.has-error {
      > .form-group__control {
        border: 1px solid $color-error;
      }
    }
  }

  &.has-error {
    color: $color-error;

    > .form-group__control {
      border-color: $color-error;
    }

    > .alert--error {
      display: block;
    }
  }
}

.form-group-area {
  > .form-group__control {
    max-width: 100%;
    min-width: 100%;
    max-height: calc(10 * 1.15em + 20px);
    min-height: calc(5 * 1.15em + 20px);
    height: auto;
    box-sizing: border-box;
    padding: 10px;
    border: none;
    background: rgba($form-color, .1);
  }

  &.has-error {
    > .form-group__control {
      border: 1px solid $color-error;
    }
  }
}
