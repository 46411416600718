.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
  box-sizing: border-box;

  @media(min-width: $bp-lg) {
    max-width: 1110px;

    &--medium {
      max-width: 850px;
    }

    &--small {
      max-width: 650px;
    }
  }
}

.columns {
  @media(min-width: $bp-lg + 1) {
    display: flex;
    flex-direction: row;

    > .col {
      flex: 1 1 50%;

      &--large {
        flex: 0 0 70.944%;
      }

      &--small {
        flex: 0 0 29.056%;
      }
    }
  }

  @media(min-width: 768px) {
    display: flex;
    flex-direction: row;
    > .col {
      flex: 1 1 50%;

      &--large {
        flex: 0 0 60%;
      }

      &--small {
        flex: 0 0 30%;
      }
    }
  }
}

.columns-reverse {
  @media(min-width: $bp-lg) {
    display: flex;
    flex-direction: column;

    &--screenshot {
      margin: auto auto auto 40px;
    }
  }
}

.columns-inverted {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  @media(min-width: $bp-lg) {
    margin-left: 40px;
  }
}

.container__contact {
  padding-top: 225px;
  @media(min-width: $bp-lg) {
    padding-top: 40px;
  }
}
