.cookie-banner-wrapper {
  display: none;
  position: fixed;
  z-index: 11;
  bottom: 0;
  width: 100%;
  background: rgba($color-neutral-dark, .6);

  &.visible {
    display: block;
  }
}

.cookie-banner {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 40px 40px;

  &__content {
    display: flex;
    align-items: center;
    max-width: 345px;
    margin: 0 auto;
    color: $color-white;
  }

  &__icon {
    width: 42px;
    height: 44px;
    margin-right: 30px;
  }

  &__cta {
    display: block;
    background-color: $color-highlight;
  }

  @media(min-width: $bp-lg) {
    display: flex;
    justify-content: center;

    &__content {
      max-width: none;
      margin: 0;
    }

    &__cta {
      margin: 20px 0 20px 10%;
    }
  }
}
