.image-wrapper {
  display: flex;
  justify-content: center;

}

.image-slider-wrapper {
  margin: 0 auto;
  @media (min-width: $bp-lg) {
    display: block;
    margin: 0;
  }
}

.image--privacy {
  width: 77px;
  height: 89px;
}

.image--runner {
  width: 330px;
  margin-bottom: -116px;

  @media (min-width: $bp-lg) {
    width: 100%;
    margin: 0;
  }
}

.image--cube {
  width: 217px;
  height: 252px;
}

.image--pentagone {
  width: 203px;
  height: 233px;

  &-contact {
    width: 111px;
    height: 123px;
  }
}

.image--nebula {
  width: 378px;
  height: 374px;
  @media (min-width: $bp-lg) {
    margin-left: -80px;
  }
}

.image--slider {
  &-case {
    width: 287px;
    margin-bottom: 30px;
  }

  &-fusion {
    width: 291px;
    margin-bottom: 46px;
  }

  &-server {
    width: 289px;
    margin-bottom: 35px;
  }

  &-locked {
    width: 289px;
    margin-bottom: 56px;
  }

  &-datacubist {
    width: 301px;
    margin-bottom: 32px;
  }
}
