.headline {
  @include font-smoothing();
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  padding-top: 50px;

  font-family: $font-family-highlight;
  color: $text-color-light;
  text-align: center;


  &__brand {
    margin: 0;
    font-size: $headline-brand-font-size-sm;
    letter-spacing: $headline-brand-letter-spacing-sm;
    line-height: $headline-brand-line-height-sm;
    font-weight: 500;
    color: $headline-title-text-color;
    text-transform: uppercase;
  }

  &__brand-highlight {
    margin: 0 0 0 15px;
    font-size: $headline-brand-highlight-font-size-sm;
    line-height: $headline-brand-highlight-line-height-sm;
    letter-spacing: $headline-brand-highlight-letter-spacing-sm;
  }

  &__provider {
    font-size: $headline-provider-font-size-sm;
    letter-spacing: $headline-provider-letter-spacing-sm;
    line-height: $headline-provider-line-height-sm;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 28px 0;

  }

  &__provider-subtitle {
    width: 286px;
    margin: 0 auto 26px;
    font-family: $font-family-base;
    font-size: 16px;
    line-height: 26px;
    color: $color-grey;
  }

  @media (min-width: $bp-lg) {
    padding-top: 40vh;

    &__brand {
      margin: 0 0 36px 0;
      font-size: $headline-brand-font-size-lg;
      font-weight: 500;
      letter-spacing: $headline-brand-letter-spacing-lg;
      color: $headline-title-text-color;
      text-transform: uppercase;
    }

    &__brand-highlight {
      margin: 0;
      font-size: $headline-brand-highlight-font-size-lg;
      letter-spacing: $headline-brand-highlight-letter-spacing-lg;
    }

    &__provider {
      margin: 0 0 30px 0;
      font-size: $headline-provider-font-size-lg;
      line-height: $headline-provider-line-height-lg;
      letter-spacing: $headline-provider-letter-spacing-lg;
    }

    &__provider-subtitle {
      width: 432px;
      margin: 0 auto 40px;
      font-size: $font-size-base;
      line-height: 24px;
    }
  }
}

.small {
  display: block;
  font-size: .7em;
  line-height: 1.2em;
  text-transform: none;
}
